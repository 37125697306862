/*
 * Copyright 2021 Motorola Solutions, Inc.
 * All Rights Reserved.
 * Motorola Solutions Confidential Restricted
 */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, CanLoad, Route, UrlSegment } from '@angular/router';
import { AuthenticationService } from '@msi/commandcentral-common-header';
import { combineLatest, Observable, of } from 'rxjs';
import { AuthenticatedSessionService } from './authenticated-session.service';
import { EnvironmentVariableService } from './../../environment/services/environment-variable.service';
import { EnvironmentVariables } from '../../environment/models/environment-variables';
import { catchError, map, take } from 'rxjs/operators';
import { UserService } from '../../user/services/user.service';

@Injectable({
  providedIn: 'root',
})
export class ConfirmAuthGuardService implements CanActivate, CanLoad {
  private routerPath: string[];
  private targetPath: string;
  private environmentVariables: EnvironmentVariables;

  constructor(
    protected router: Router,
    private authenticationService: AuthenticationService,
    private environmentVariableService: EnvironmentVariableService,
    private userService: UserService,
    private authenticatedSessionService: AuthenticatedSessionService
  ) {}

  private setRouterPath(route: ActivatedRouteSnapshot): void {
    const keyString = '_urlSegment';
    this.routerPath =
      route && route[keyString] && route[keyString].segments ? [...route[keyString].segments.map((url) => url.path)] : undefined;
  }

  public async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    this.setRouterPath(route);
    console.log('Confirm Auth Can Activate is called');
    return this.isAuthenticated();
  }

  public canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | boolean {
    console.log('Conf Auth can load called');

    this.environmentVariableService.observableEnvironmentVariables.subscribe((environmentVariables) => {
      console.log('Got environment variable');
      this.environmentVariables = environmentVariables;
    });
    const fullPath = segments.reduce((path, currentSegment) => {
      return `${path}/${currentSegment.path}${window.location.search}`;
    }, '');
    console.log('-----------Conf Auth Guard: can load with path:' + fullPath);
    this.targetPath = fullPath.split('&code=')[0];
    const customerIdValue = this.targetPath.split('?customerId=')[1].split('&agencyId=')[0];
    console.log('Set Customer id value is:' + customerIdValue);
    this.authenticatedSessionService.setCustomerId(customerIdValue);
    return of(true);
  }
  public async isAuthenticated(): Promise<boolean> {
    console.log('Conf Auth Guard: Start common header authentication');
    let i = 0;
    let savedToken: string;

    while (i < 3 && savedToken === undefined) {
    savedToken = await this.authenticationService.authenticate();
      i++;
    }
    if (savedToken) {
      console.log('Retrieved token successfully');
      this.authenticatedSessionService.setToken(savedToken);
      this.authenticatedSessionService.setTargetPage(this.targetPath);

      // what do we do with this?
      // We want to return once we've received all the information we'll need to authenticate
      return await combineLatest([this.environmentVariableService.observableEnvironmentVariables, this.userService.user$])
        .pipe(
          take(1),
          map(
            () => {
              console.log('Retrieved user and env');
              return true;
            },
            catchError(() => {
              console.log('Error in user and env retrieval');
              return of(false);
            })
          )
        )
        .toPromise();
    } else {
      console.log('Failed to get token in three try');
      this.router.navigate(['unauthorized'], {
        preserveFragment: true,
        queryParamsHandling: 'preserve',
      });
      return false;
    }
  }
}
